import { defaultFormSchema } from "@/schemas/defaultFormSchema.ts";
import { FormSchema } from "./schema";

const matchResultFormSchema: FormSchema = {
  programId: {
    ...defaultFormSchema.programId,
    description:
      "Select the program you matched into. This will automatically update your rank list if you created one.",
  },
  rankNumber: {
    type: "number",
    label: "Rank number",
    description: "What number was this program on your rank list?",
  },
  import: defaultFormSchema.import,
  graduateType: defaultFormSchema.graduateType,
  img: defaultFormSchema.img,
  geographicPreference: defaultFormSchema.geographicPreference,
  signalTier: {
    type: "select",
    label: "Signal",
    description: "Select if you used a signal for this program.",
    // conditions: { signal: true },
    placeholder: "Select signal",
    width: "125px",
  },
  inState: defaultFormSchema.inState,
  medicalDegree: defaultFormSchema.medicalDegree,
  visaRequired: defaultFormSchema.visaRequired,
  subI: defaultFormSchema.subI,
  home: defaultFormSchema.home,
  greenCard: defaultFormSchema.greenCard,
  away: defaultFormSchema.away,
  pstp: defaultFormSchema.pstp,
  step1ScorePass: defaultFormSchema.step1ScorePass,
  step1Score: defaultFormSchema.step1Score,
  step2Score: defaultFormSchema.step2Score,
  step3Score: defaultFormSchema.step3Score,
  comlex1ScorePass: defaultFormSchema.comlex1ScorePass,
  comlex2Score: defaultFormSchema.comlex2Score,
  aoa: defaultFormSchema.aoa,
  sigmaSigmaPhi: defaultFormSchema.sigmaSigmaPhi,
  goldHumanism: defaultFormSchema.goldHumanism,
  honors: defaultFormSchema.honors,
  highPass: defaultFormSchema.highPass,
  pass: defaultFormSchema.pass,
  fail: defaultFormSchema.fail,
  yearOfGraduation: defaultFormSchema.yearOfGraduation,
  anonymous: defaultFormSchema.anonymous,
  save: defaultFormSchema.save,
};

export default matchResultFormSchema;
